import "../Pages.css";
import { appData, ChargerPurchaseType } from "../../data";
import ImageLayout2 from "../../components/ImageLayouts/ImageLayout2";

interface ChargerPurchaseOtherProps {
  updateData(type: string, newData: ChargerPurchaseType): void;
}

export default function ChargerPurchaseOther({
  updateData,
}: ChargerPurchaseOtherProps) {
  const selectedOption = (option: string) => {
    updateData("chargerPurchaseTypePg", {
      purchaseChargerType: option,
      cost:
        appData.purchaseLeaseChargerOptionsOther.cost[
          appData.purchaseLeaseChargerOptionsOther.type.indexOf(option)
        ],
    });
  };

  return (
    <div className="CSO">
      <ImageLayout2
        selectedOption={selectedOption}
        title="PLEASE SELECT THE CHARGER YOU WOULD LIKE TO PURCHASE"
        option_A={appData.purchaseLeaseChargerOptionsOther.type[0]}
        option_B={appData.purchaseLeaseChargerOptionsOther.type[1]}
        option_A_img={appData.purchaseLeaseChargerOptionsOther.img[0]}
        option_B_img={appData.purchaseLeaseChargerOptionsOther.img[1]}
      />
    </div>
  );
}
