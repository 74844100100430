import React from "react";
import "./ElectricalInfo.css";

interface TextTitleProp {
  title: string;
  value: string;
  setValue: (value: string) => void;
}

export default function Main({ title, value, setValue }: TextTitleProp) {
  return (
    <div className="MultiselectTitle">
      <div className="title">
        <h1>{title}</h1>
      </div>

      <div className="mySelect">
        <div className="mySelect">
          <div className="mySelectContent">
            <input
              className="inputText"
              placeholder="Address"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.0)",
                borderColor: "rgba(0, 0, 0, 0.0)",
                width: "100%",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
              type="text"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
