import React from "react";
import {
  AddressData,
  PageFormData,
  VehicleMake,
  HouseType,
  ChargerOption,
  JacuzziOption,
  ChargerPurchase,
  ChargerPurchaseType,
  ContactInfo,
  ElectricalInfo,
} from "./data";

import HomePG from "./Pages/AddressPG";
import ChargingOptionTeslaPG from "./Pages/ChargingOptions/ChargingOptionTeslaPG";
import ChargingPurchaseOtherPG from "./Pages/ChargingPurchase/ChargingPurchaseOtherPG";
import ChargingPurchaseTeslaPG from "./Pages/ChargingPurchase/ChargingPurchaseTeslaPG";
import ChargingPurchasePG from "./Pages/ChargingPurchase/ChargingPurchasePG";
import HouseTypePG from "./Pages/HouseTypePG";
import JacuzziPG from "./Pages/JacuzziPG";
import VehicelMakePG from "./Pages/VehicleMakePg";
import ContactPG from "./Pages/ContactPG";
import ElectricalInfoPG from "./Pages/ElectricalInfoPG";
import ChargingOptionOtherPG from "./Pages/ChargingOptions/ChargingOptionOtherPG";
import EditPG from "./Pages/EditPG";
import { uploadData } from "./FireBase/FirebaseFunctions";
import QuotePg from "./Pages/QuotePG";
export default function Form() {
  const [page, setPage] = React.useState(0);

  const [data, setData] = React.useState<PageFormData>({
    addressPg: { address: "" },
    vehicleMakePg: { vehicle: "" },
    houseTypePg: { home: "" },
    chargerOptionPg: { charger: "", cost: 0 },
    electricalInfoPg: {
      panalLocation: "",
      mainServerSize: "",
      photoElectricalPannle: undefined,
      photoMainBreaker: undefined,
      wireLenght: "",
    },
    jacuzziOptionPg: { jacuzzi: "" },
    chargerPurchasePg: { purchaseCharger: "" },
    chargerPurchaseTypePg: { purchaseChargerType: "", cost: 0 },
    contactInfoPg: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      additionalInfo: "",
    },
  });

  function updateData(
    type: string,
    newData:
      | AddressData
      | VehicleMake
      | HouseType
      | ChargerOption
      | JacuzziOption
      | ChargerPurchase
      | ChargerPurchaseType
      | ElectricalInfo,
    noCharger: boolean = false
  ) {
    setData((prev) => {
      return { ...prev, [type]: newData };
    });
    noCharger && setPage((prev) => prev + 1);
    setPage((prev) => prev + 1);
  }

  function goBack() {
    setPage(/*(prev) => prev - 1*/ 8);
  }

  function updateContactData(type: string, newData: ContactInfo) {
    setData((prev) => {
      return { ...prev, [type]: newData };
    });
    setPage((prev) => prev + 1);
  }

  function updateDataEdit(data: PageFormData) {
    setData(data);
    setPage((prev) => prev + 1);
  }

  function uploadDataToFirebase(data: PageFormData) {
    uploadData(data);
    setPage((prev) => prev + 1);
  }
  return (
    <>
      {page === 0 && <HomePG updateData={updateData} />}

      {page === 1 && <VehicelMakePG updateData={updateData} />}

      {page === 2 && <HouseTypePG updateData={updateData} />}

      {page === 3 && data.vehicleMakePg.vehicle === "Tesla" && (
        <ChargingOptionTeslaPG updateData={updateData} />
      )}
      {page === 3 && data.vehicleMakePg.vehicle === "Other" && (
        <ChargingOptionOtherPG updateData={updateData} />
      )}

      {page === 4 && <ElectricalInfoPG updateData={updateData} data={data} />}

      {page === 5 && <JacuzziPG updateData={updateData} />}

      {page === 6 && <ChargingPurchasePG updateData={updateData} data={data} />}

      {page === 7 &&
        data.chargerPurchasePg.purchaseCharger !==
          "No, I will be providing my own charger" &&
        data.vehicleMakePg.vehicle === "Tesla" && (
          <ChargingPurchaseTeslaPG updateData={updateData} />
        )}
      {page === 7 &&
        data.chargerPurchasePg.purchaseCharger !==
          "No, I will be providing my own charger" &&
        data.vehicleMakePg.vehicle === "Other" && (
          <ChargingPurchaseOtherPG updateData={updateData} />
        )}

      {page === 8 && <EditPG data={data} updateDataEdit={updateDataEdit} />}

      {page === 9 && <ContactPG updateData={updateContactData} data={data} />}

      {page === 10 && (
        <QuotePg
          data={data}
          backFunc={goBack}
          uploadData={uploadDataToFirebase}
        />
      )}

      {page === 11 && <h1>Thank you</h1>}
    </>
  );
}
