import "./Pages.css";
import { appData, HouseType } from "../data";
import ImageLayout4 from "../components/ImageLayouts/ImageLayout4";

interface HouseTypeProps {
  updateData(type: string, newData: HouseType): void;
}

export default function HouseTypePG({ updateData }: HouseTypeProps) {
  const selectedOption = (option: string) => {
    updateData("houseTypePg", { home: option });
  };

  return (
    <div className="VM">
      <ImageLayout4
        selectedOption={selectedOption}
        title="TYPE OF HOUSE"
        option_A={appData.houseType.type[0]}
        option_B={appData.houseType.type[1]}
        option_C={appData.houseType.type[2]}
        option_D={appData.houseType.type[3]}
        option_A_img={appData.houseType.imgs[0]}
        option_B_img={appData.houseType.imgs[1]}
        option_C_img={appData.houseType.imgs[2]}
        option_D_img={appData.houseType.imgs[3]}
      />
    </div>
  );
}
