import './ImageLayout.css';

interface ImageLayout4Prop {
    selectedOption(name: string): void,
    title: string, 
    option_A: string, 
    option_A_img: string,
    option_B: string,
    option_B_img: string,
    option_C: string,
    option_C_img: string,
    option_D: string,
    option_D_img: string,
}


export default function ImageLayout4({selectedOption, title, option_A, option_A_img, option_B, option_B_img, option_C, option_C_img, option_D, option_D_img}:ImageLayout4Prop){
    return (
        <div className="options_4">
                <div className="header">
                    <h1>{title}</h1>
                </div>
                <div className="option_A" onClick={() => selectedOption(option_A)}>
                    <div className="item" style={{background: `url(${option_A_img}) center`}}>
                        <h1>{option_A}</h1>
                    </div>
                </div>
                <div className="option_B" onClick={() => selectedOption(option_B)}>
                    <div className="item" style={{background: `url(${option_B_img}) center`}}>
                    <h1>{option_B}</h1>
                    </div>
                </div>
                <div className="option_C" onClick={() => selectedOption(option_C)}>
                    <div className="item" style={{background: `url(${option_C_img}) center`}}>
                    <h1>{option_C}</h1>
                    </div>
                </div>
                <div className="option_D" onClick={() => selectedOption(option_D)}>
                    <div className="item" style={{background: `url(${option_D_img}) center`}}>
                    <h1>{option_D}</h1>
                    </div>
                </div>
        </div>
    )
}

ImageLayout4.defaultProps = {
    title: 'SELECT YOUR VEHICLE MAKE',
    option_A: 'OPTION A',
    option_B: 'OPTION B',
    option_C: 'OPTION C',
    option_D: 'OPTION D',
}