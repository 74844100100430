import "./Pages.css";
import { VehicleMake } from "../data";
import ImageLayout2 from "../components/ImageLayouts/ImageLayout2";

interface VehicelMakeProps {
  updateData(type: string, newData: VehicleMake): void;
}

export default function VehicelMakePG({ updateData }: VehicelMakeProps) {
  const selectedOption = (option: string) => {
    updateData("vehicleMakePg", { vehicle: option });
  };

  return (
    <div className="VM">
      <ImageLayout2
        selectedOption={selectedOption}
        title="SELECT YOUR VEHICLE MAKE"
        option_A="Tesla"
        option_B="Other"
        option_A_img={require("../images/Tesla.jpg")}
        option_B_img={require("../images/Other.jpg")}
      />
    </div>
  );
}
