import { PageFormData } from "../data";
import "./Pages.css";
import Quote from ".././components/Quote/Quote";

interface QuoteProps {
  data: PageFormData;
  backFunc(): void;
  uploadData(data: PageFormData): void;
}

export default function QuotePg({ data, backFunc, uploadData }: QuoteProps) {
  return (
    <div className="Qo">
      <Quote data={data} backFunc={backFunc} uploadData={uploadData} />
    </div>
  );
}
