import React from "react";
import BusinessCredential from "../../components/BusinessCredential/BusinessCredential";
import { ContactInfo, PageFormData } from "../../data";
import "./ContactInfo.css";
import validator from "validator";

interface ContactProp {
  selectedOption(name: ContactInfo): void;
  data: PageFormData;
}

export default function ImageLayout1({ selectedOption, data }: ContactProp) {
  const [fName, setFName] = React.useState(data.contactInfoPg.firstName);
  const [lName, setLName] = React.useState(data.contactInfoPg.lastName);
  const [email, setEmail] = React.useState(data.contactInfoPg.email);
  const [phoneNo, setPhoneNo] = React.useState(data.contactInfoPg.phoneNumber);
  const [info, setInfo] = React.useState(data.contactInfoPg.additionalInfo);

  const [errorOutline, setErrorOutline] = React.useState({
    fName: false,
    lName: false,
    email: false,
    phoneNo: false,
  });

  const onNextClick = () => {
    let errorFound =
      fName === "" ||
      lName === "" ||
      !validator.isEmail(email) ||
      !validator.isMobilePhone(phoneNo);

    setErrorOutline({
      fName: fName === "",
      lName: lName === "",
      email: !validator.isEmail(email),
      phoneNo: !validator.isMobilePhone(phoneNo),
    });

    !errorFound &&
      selectedOption({
        firstName: fName,
        lastName: lName,
        email: email,
        phoneNumber: phoneNo,
        additionalInfo: info,
      });
  };

  const myStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.0)",
    borderColor: "rgba(0, 0, 0, 0.0)",
    width: "100%",
    height: "90%",
  };

  const errorStyle = { border: "4px dashed red", borderRadius: "2vw" };

  return (
    <div className="container">
      <div className="contact">
        <div className="title">
          <h1>CONTACT INFOMATION</h1>
        </div>
        <div className="contentContainer">
          <div className="contactInfo">
            <div className="firstLastNameContainer">
              <div
                className="fNameText"
                style={errorOutline.fName ? errorStyle : {}}
              >
                <input
                  placeholder="First Name"
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.0)",
                    borderColor: "rgba(0, 0, 0, 0.0)",
                    width: "100%",
                  }}
                  type="text"
                  value={fName}
                  onChange={(e) => setFName(e.target.value)}
                />
              </div>
              <div
                className="lNameText"
                style={errorOutline.lName ? errorStyle : {}}
              >
                <input
                  placeholder="Last Name"
                  style={myStyle}
                  type="text"
                  value={lName}
                  onChange={(e) => setLName(e.target.value)}
                />
              </div>
            </div>

            <div
              className="emailContainer"
              style={errorOutline.email ? errorStyle : {}}
            >
              <div className="email">
                <input
                  placeholder="E-mail Address"
                  style={myStyle}
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div
              className="phoneNumberContianer"
              style={errorOutline.phoneNo ? errorStyle : {}}
            >
              <div className="phoneNumber">
                <input
                  placeholder="Phone Number"
                  style={myStyle}
                  type="text"
                  value={phoneNo}
                  onChange={(e) => setPhoneNo(e.target.value)}
                />
              </div>
            </div>
            <div className="additionalInfoContainer">
              <div className="additionalInfo">
                <textarea
                  placeholder="Additional Information"
                  style={myStyle}
                  value={info}
                  onChange={(e) => setInfo(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="bCredential">
            <BusinessCredential />
          </div>
        </div>
        <div className="buttonContainer">
          <div className="button" onClick={onNextClick}>
            <h1>RECEIVE ESTIMATE</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
