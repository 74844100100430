import "./Pages.css";
import { JacuzziOption } from ".././data";
import ImageLayout1YesNo from ".././components/ImageLayouts/ImageLayout1YesNo";

interface JacuzziProps {
  updateData(type: string, newData: JacuzziOption): void;
}

export default function Jacuzzi({ updateData }: JacuzziProps) {
  const selectedOption = (option: string) => {
    updateData("jacuzziOptionPg", { jacuzzi: option });
  };

  return (
    <div className="J">
      <ImageLayout1YesNo
        selectedOption={selectedOption}
        title="DO YOU HAVE AN ELEVATOR, POOL OR HOT TUB?"
        option_A_img={require("../images/Hot-Tub.jpg")}
      />
    </div>
  );
}
