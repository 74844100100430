import React from "react";
import { appData, PageFormData } from "../../data";
import "./Edit.css";
import Multiselect from "../ElectricalInfo/Multiselect";
import TextTitle from "../ElectricalInfo/TextTitle";
import UploadFile from "../ElectricalInfo/UploadFile";

interface EditProp {
  data: PageFormData;
  updateDataEdit: (data: PageFormData) => void;
}

const errorStyle = { border: "4px dashed red", borderRadius: "2vw" };

export default function Main({ data, updateDataEdit }: EditProp) {
  //only need to select charger
  const [errorOutline, setErrorOutline] = React.useState(false);

  const onNextClick = () => {
    let error =
      dataFinal.chargerPurchaseTypePg.purchaseChargerType === "" &&
      dataFinal.chargerPurchasePg.purchaseCharger !==
        appData.purchaseLeaseCharger[2];

    setErrorOutline(error);

    console.log("wire Lne: ", dataFinal.electricalInfoPg.wireLenght);

    !error && updateDataEdit(dataFinal);
  };

  const [dataFinal, setDataFinal] = React.useState<PageFormData>(data);

  return (
    <div className="edit">
      <h1 className="titleMain">PLEASE REVIEW YOUR SELECTIONS</h1>
      <div className="section">
        <div className="sectionA">
          <TextTitle
            title="ADDRESS"
            value={dataFinal.addressPg.address}
            setValue={(value: string) => {
              setDataFinal((prev) => {
                prev.addressPg.address = value;
                return { ...prev };
              });
            }}
          />

          <Multiselect
            title="VEHICLE MAKE"
            options={["Tesla", "Other"]}
            value={dataFinal.vehicleMakePg.vehicle}
            setValue={(value: string) => {
              setDataFinal((prev) => {
                prev.vehicleMakePg.vehicle = value;

                prev.chargerPurchaseTypePg.cost = 0;
                prev.chargerPurchaseTypePg.purchaseChargerType = "";
                prev.chargerOptionPg.cost = 0;
                prev.chargerOptionPg.charger = "";
                return {
                  ...prev,
                };
              });
            }}
          />

          <Multiselect
            title="TYPE OF HOUSE"
            options={appData.houseType.type}
            value={dataFinal.houseTypePg.home}
            setValue={(value: string) => {
              setDataFinal((prev) => {
                prev.houseTypePg.home = value;
                return {
                  ...prev,
                };
              });
            }}
          />

          <Multiselect
            title="CHARGING OPTION"
            options={
              dataFinal.vehicleMakePg.vehicle === "Tesla"
                ? appData.chargingOptionTesla.type
                : appData.chargingOptionOther.type
            }
            value={dataFinal.chargerOptionPg.charger}
            setValue={(value: string) => {
              setDataFinal((prev) => {
                prev.chargerOptionPg.charger = value;
                dataFinal.vehicleMakePg.vehicle === "Tesla"
                  ? (prev.chargerOptionPg.cost =
                      appData.chargingOptionTesla.cost[
                        appData.chargingOptionTesla.type.indexOf(value)
                      ])
                  : (prev.chargerOptionPg.cost =
                      appData.chargingOptionOther.cost[
                        appData.chargingOptionOther.type.indexOf(value)
                      ]);
                return {
                  ...prev,
                };
              });
            }}
          />

          <Multiselect
            title="DO YOU HAVE AN ELEVATOR, POOL OR HOT TUB?"
            options={["Yes", "No"]}
            value={dataFinal.jacuzziOptionPg.jacuzzi}
            setValue={(value: string) => {
              setDataFinal((prev) => {
                prev.jacuzziOptionPg.jacuzzi = value;
                return {
                  ...prev,
                };
              });
            }}
          />

          <Multiselect
            title="WOULD YOU LIKE TO PURCHASE/LEASE THE CHARGER"
            options={appData.purchaseLeaseCharger}
            value={dataFinal.chargerPurchasePg.purchaseCharger}
            setValue={(value: string) => {
              setDataFinal((prev) => {
                prev.chargerPurchasePg.purchaseCharger = value;
                prev.chargerPurchaseTypePg.purchaseChargerType =
                  value === "No, I will be providing my own charger"
                    ? ""
                    : prev.chargerPurchaseTypePg.purchaseChargerType;
                value === "No, I will be providing my own charger" &&
                  (prev.chargerPurchaseTypePg.cost = 0);
                return {
                  ...prev,
                };
              });
            }}
          />
        </div>
        <div className="sectionB">
          <Multiselect
            title="ELECTRICAL PANEL LOCATION"
            options={appData.electricalPanelLocation}
            value={dataFinal.electricalInfoPg.panalLocation}
            setValue={(value: string) => {
              setDataFinal((prev) => {
                prev.electricalInfoPg.panalLocation = value;
                return {
                  ...prev,
                };
              });
            }}
          />

          <Multiselect
            title="MAIN SERVICE SIZE"
            options={appData.mainServerSize}
            value={dataFinal.electricalInfoPg.mainServerSize}
            setValue={(value: string) => {
              setDataFinal((prev) => {
                prev.electricalInfoPg.mainServerSize = value;
                return {
                  ...prev,
                };
              });
            }}
          />

          <UploadFile
            title="PHOTO OF THE ELECTRICAL PANEL"
            value={dataFinal.electricalInfoPg.photoElectricalPannle}
            setValue={(value: File) => {
              setDataFinal((prev) => {
                prev.electricalInfoPg.photoElectricalPannle = value;
                return {
                  ...prev,
                };
              });
            }}
          />

          <UploadFile
            title="PHOTO OF THE MAIN BREAKER"
            value={dataFinal.electricalInfoPg.photoMainBreaker}
            setValue={(value: File) => {
              setDataFinal((prev) => {
                prev.electricalInfoPg.photoMainBreaker = value;
                return {
                  ...prev,
                };
              });
            }}
          />

          <Multiselect
            title="WIRE LENGTH FROM PANEL TO CHARGER"
            options={appData.wireLenghtPanelToCharger}
            value={dataFinal.electricalInfoPg.wireLenght}
            setValue={(value: string) => {
              setDataFinal((prev) => {
                prev.electricalInfoPg.wireLenght = value;
                return {
                  ...prev,
                };
              });
            }}
          />

          <div style={errorOutline ? errorStyle : {}}>
            {dataFinal.chargerPurchasePg.purchaseCharger !==
              "No, I will be providing my own charger" && (
              <Multiselect
                title="SELECT THE CHARGER YOU’D LIKE TO PURCHASE"
                options={
                  data.vehicleMakePg.vehicle === "Tesla"
                    ? appData.purchaseLeaseChargerOptionsTesla.type
                    : appData.purchaseLeaseChargerOptionsOther.type
                }
                value={dataFinal.chargerPurchaseTypePg.purchaseChargerType}
                setValue={(value: string) => {
                  setDataFinal((prev) => {
                    prev.vehicleMakePg.vehicle === "Tesla"
                      ? (prev.chargerPurchaseTypePg.cost =
                          appData.purchaseLeaseChargerOptionsTesla.cost[
                            appData.purchaseLeaseChargerOptionsTesla.type.indexOf(
                              value
                            )
                          ])
                      : (prev.chargerPurchaseTypePg.cost =
                          appData.purchaseLeaseChargerOptionsOther.cost[
                            appData.purchaseLeaseChargerOptionsOther.type.indexOf(
                              value
                            )
                          ]);

                    prev.chargerPurchaseTypePg.purchaseChargerType = value;
                    return {
                      ...prev,
                    };
                  });
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="buttonContainer">
        <div className="button" onClick={onNextClick}>
          <h1>NEXT</h1>
        </div>
      </div>
    </div>
  );
}
