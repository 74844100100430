import "../Pages.css";
import { appData, ChargerPurchaseType } from "../../data";
import ImageLayout from "../../components/ImageLayouts/ImageLayout1";

interface ChargerPurchaseOtherProps {
  updateData(type: string, newData: ChargerPurchaseType): void;
}

export default function ChargerPurchaseOther({
  updateData,
}: ChargerPurchaseOtherProps) {
  const selectedOption = (option: string) => {
    updateData("chargerPurchaseTypePg", {
      purchaseChargerType: option,
      cost:
        appData.purchaseLeaseChargerOptionsTesla.cost[
          appData.purchaseLeaseChargerOptionsTesla.type.indexOf(option)
        ],
    });
  };

  return (
    <div className="CPT">
      <ImageLayout
        selectedOption={selectedOption}
        title="PLEASE SELECT THE CHARGER YOU WOULD LIKE TO PURCHASE"
        option_A={appData.purchaseLeaseChargerOptionsTesla.type[0]}
        option_A_img={appData.purchaseLeaseChargerOptionsTesla.img[0]}
      />
    </div>
  );
}
