import "../Pages.css";
import { appData, ChargerPurchase, PageFormData } from "../../data";
import PurchaseCharger from "../../components/PurchaseCharger/PurchaseCharger";

interface ChargerPurchaseProps {
  updateData(type: string, newData: ChargerPurchase, noCharger: boolean): void;
  data: PageFormData;
}

export default function ChargerPurchaseOther({
  updateData,
  data,
}: ChargerPurchaseProps) {
  const selectedOption = (option: string) => {
    updateData(
      "chargerPurchasePg",
      { purchaseCharger: option },
      option === appData.purchaseLeaseCharger[2] ? true : false
    );
  };

  return (
    <div className="CSO">
      <PurchaseCharger selectedOption={selectedOption} data={data} />
    </div>
  );
}
