import "./Quote.css";
import { v4 as uuid } from "uuid";

interface ServiceItemsProp {
  headingLable: string;
  items: Array<ServiceItem>;
}

export interface ServiceItem {
  itemLable: string;
  itemPrice: string;
}

export default function serviceItems({
  headingLable,
  items,
}: ServiceItemsProp) {
  let itemsList = items.map((item: ServiceItem) => {
    return (
      <div className="servicesList" key={uuid()}>
        <p>{item.itemLable}</p> <p>{item.itemPrice}</p>
      </div>
    );
  });

  return (
    <div className="servicesMaterialCosts">
      <div className="services">
        <p>{headingLable}</p> <p>AMOUNT</p>
      </div>

      {itemsList}
    </div>
  );
}
