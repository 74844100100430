import "../Pages.css";
import { appData, ChargerOption } from "../../data";
import ImageLayout3 from "../../components/ImageLayouts/ImageLayout3";

interface ChargerOptionOtherProps {
  updateData(type: string, newData: ChargerOption): void;
}

export default function ChargingOptionOtherPG({
  updateData,
}: ChargerOptionOtherProps) {
  const selectedOption = (option: string) => {
    updateData("chargerOptionPg", {
      charger: option,
      cost:
        appData.chargingOptionOther.cost[
          appData.chargingOptionOther.type.indexOf(option)
        ],
    });
  };

  return (
    <div className="COO">
      <ImageLayout3
        selectedOption={selectedOption}
        title="SELECT CHARGING OPTION"
        option_A={appData.chargingOptionOther.type[0]}
        option_B={appData.chargingOptionOther.type[1]}
        option_C={appData.chargingOptionOther.type[2]}
        option_A_img={appData.chargingOptionOther.imgs[0]}
        option_B_img={appData.chargingOptionOther.imgs[1]}
        option_C_img={appData.chargingOptionOther.imgs[2]}
      />
    </div>
  );
}
