import "./MapSearch.css";
import { TiLocationOutline } from "react-icons/ti";
import { AddressData, VehicleMake } from "../../data";
import React from "react";
import Autocomplete from "react-google-autocomplete";
interface MapSearchProps {
  updateData(type: string, newData: AddressData | VehicleMake): void;
}

export default function MapSearch({ updateData }: MapSearchProps) {
  const [address, setAddress] = React.useState("");
  const [selectedAddress, setSelectedAddress] = React.useState(false);

  const onNextClick = () => {
    selectedAddress && updateData("addressPg", { address: address });
  };
  return (
    <div className="mapSearchContainer">
      <TiLocationOutline id="mapIcon" />
      <Autocomplete
        id="mapSearchTextField"
        apiKey="AIzaSyDM7yEg_TBrglYPjkVDRcqP3UvnHldH1Lo"
        onPlaceSelected={(place) => {
          setAddress(place.formatted_address);
          setSelectedAddress(true);
        }}
        onChange={(place) => {
          setAddress(place.currentTarget.value);
          setSelectedAddress(false);
        }}
        options={{
          types: ["address"],
          componentRestrictions: { country: "ca" },
        }}
        placeholder="Enter your home address"
      />
      <button
        type="button"
        id="mapSearchBtn"
        style={selectedAddress ? {} : { backgroundColor: "maroon" }}
        onClick={onNextClick}
      >
        GO
      </button>
    </div>
  );
}
