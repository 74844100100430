import React from "react";
import "./ElectricalInfo.css";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormControl, InputLabel, styled } from "@mui/material";

const MuiSelect = styled(Select)(() => ({
  "& .MuiOutlinedInput-notchedOutline": {
    padding: "10px 12px 10px 12px",
    borderColor: "rgb(0,0,0,0)!important",
    fontSize: "15px",
  },
}));

interface MultiselectTitleProp {
  title: string;
  options: Array<string>;
  value: string;
  setValue: (value: string) => void;
}

export default function Main({
  title,
  options,
  value,
  setValue,
}: MultiselectTitleProp) {
  let menueOptions = options.map((t: string) => (
    <MenuItem value={t} key={t}>
      {t}
    </MenuItem>
  ));

  return (
    <div className="MultiselectTitle" style={{ fontSize: "15px!important" }}>
      <div className="title">
        <h1>{title}</h1>
      </div>

      <div className="mySelect">
        <FormControl variant="outlined" fullWidth>
          <InputLabel
            style={{
              fontSize: 15,
            }}
          >
            Please Select
          </InputLabel>
          <MuiSelect
            value={value}
            label="Please Selected"
            onChange={(e: any) => setValue(e.target.value)}
          >
            {menueOptions}
          </MuiSelect>
        </FormControl>
      </div>
    </div>
  );
}
