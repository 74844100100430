import React, { useEffect } from "react";
import { PageFormData, appData } from "../../data";
import "./Quote.css";
import ServiceItems from "./ServiceItemList";
import Moment from "moment";

interface QuoteProp {
  data: PageFormData;
  backFunc(): void;
  uploadData(data: PageFormData): void;
}

export default function Quote({ data, backFunc, uploadData }: QuoteProp) {
  const [serviceData, setServiceData] = React.useState({ label: "", cost: 0 });

  function formatPhoneNumber(str: string) {
    //Filter only numbers from the input
    let cleaned = ("" + str).replace(/\D/g, "");

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return match[1] + "-" + match[2] + "-" + match[3];
    }

    return null;
  }

  function titleCase(str: string) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(" ");
  }

  const assumptions = (
    <div className="assumptionsTerms">
      {`
      Panel has 2 spare spaces and breakers (fuses not acceptable)
      Work to be completed 8 am to 4 pm weekdays
      Electrical service can support a EV load
      Location is Oakville, Mississauga, Burlington, Hamilton, Milton
      Unfinished basement fishing of cable may be additional
      Indoor cable exterior (teck) cable and connectors additional
      Extended warranty $ 125.00 per/year full replacement
      Estimate valid for 30 days
      HST 87149 0777`}
    </div>
  );

  const assumptionsExtra = (
    <div className="assumptionsTerms">
      {`
      Panel has 2 spare spaces and breakers (fuses not acceptable)
      Work to be completed 8 am to 4 pm weekdays
      Electrical service can support a EV load
      Location is Oakville, Mississauga, Burlington, Hamilton, Milton
      Unfinished basement fishing of cable may be additional
      Indoor cable exterior (teck) cable and connectors additional
      Extended warranty $ 125.00 per/year full replacement
      No troubleshooting required for provided EV charger
      Estimate valid for 30 days
      HST 87149 0777`}
    </div>
  );

  const [materialData, setMaterialData] = React.useState({
    label: "",
    cost: 0,
  });

  const [priceData, setPriceData] = React.useState({
    sub: 0,
    hst: 0,
    total: 0,
  });

  //calc data for service price = wirelenght + charger cost
  //calc data for matiral price = chargerPrice
  //total price

  useEffect(() => {
    const calculateQuote = () => {
      //service price
      let wireCost =
        appData.wireCost[
          appData.wireLenghtPanelToCharger.indexOf(
            data.electricalInfoPg.wireLenght
          )
        ];

      let plugCost = data.chargerOptionPg.cost;

      console.log(
        "p: ",
        plugCost,
        ". X: ",
        wireCost,
        ", wire: ",
        data.electricalInfoPg.wireLenght
      );

      const serviceCost = plugCost + wireCost;
      const materialCost = data.chargerPurchaseTypePg.cost;

      const serviceLable = `EV Charger installation
        - ${data.electricalInfoPg.wireLenght} Wire
        - ${data.chargerOptionPg.charger}`;

      setServiceData({ label: serviceLable, cost: serviceCost });
      //material price
      setMaterialData({
        label: data.chargerPurchaseTypePg.purchaseChargerType,
        cost: materialCost,
      });

      const sub = materialCost + serviceCost;
      const hst = sub * 0.13;
      const total = sub + hst;

      setPriceData({
        sub: sub,
        hst: hst,
        total: total,
      });
    };

    calculateQuote();
  }, [
    data.chargerOptionPg.cost,
    data.chargerPurchaseTypePg.cost,
    data.chargerPurchaseTypePg.purchaseChargerType,
    data.electricalInfoPg,
    data.chargerOptionPg.charger,
  ]);

  const addressArray = data.addressPg.address.split(", ");

  return (
    <div className="quote">
      <div className="logo">
        <img src={require("../../images/Logo1.png")} alt="Logo" />
      </div>
      <div className="addressDate">
        <div className="address">
          <p>
            {titleCase(data.contactInfoPg.firstName)}{" "}
            {titleCase(data.contactInfoPg.lastName)}
          </p>
          <p>{addressArray[0] ? addressArray[0] : ""}</p>
          <p>
            {addressArray[1]
              ? addressArray[1] +
                (addressArray[2] ? ", " + addressArray[2] : "")
              : ""}
          </p>
          <br />
          <p>{formatPhoneNumber(data.contactInfoPg.phoneNumber)}</p>
          <p>{data.contactInfoPg.email}</p>
        </div>
        <div className="date">
          <p style={{ fontWeight: "bolder" }}>DATE: </p>
          <p>{Moment(Date()).format("D-MMM-YYYY")}</p>
        </div>
      </div>

      <ServiceItems
        headingLable="SERVICES"
        items={[
          {
            itemLable: serviceData.label,
            itemPrice: `$${serviceData.cost.toLocaleString("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}`,
          },
        ]}
      />

      <ServiceItems
        headingLable="MATERIAL"
        items={[
          {
            itemLable:
              materialData.label === ""
                ? "N/A Customer will provide EV charger"
                : materialData.label,
            itemPrice: `$${materialData.cost.toLocaleString("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}`,
          },
        ]}
      />

      <div className="servicesMaterialCosts">
        <div className="services">
          <p>ASSUMPTIONS</p>
        </div>
        {data.chargerPurchasePg.purchaseCharger ===
        appData.purchaseLeaseCharger[2]
          ? assumptionsExtra
          : assumptions}
      </div>

      <div className="btnsTotal">
        <div className="btnSection">
          <div className="btns">
            <div className="approveBtn" onClick={() => uploadData(data)}>
              <h1>APPROVE</h1>
            </div>
            <div className="declineBtn" onClick={backFunc}>
              <h1>REVISE</h1>
            </div>
          </div>
          <p style={{ fontStyle: "italic" }}>
            Upon approval of this estimate you will be able to schedule a
            installation date.
          </p>
        </div>

        <div className="total">
          <div className="totalLable">
            <p>SUB TOTAL</p>
            <p>HST</p>
            <p>TOTAL</p>
          </div>
          <div className="totalValue">
            <p>
              $
              {priceData.sub.toLocaleString("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </p>
            <p>
              $
              {priceData.hst.toLocaleString("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </p>
            <p>
              $
              {priceData.total.toLocaleString("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
