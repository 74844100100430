import React from "react";
import "./PurchaseCharger.css";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormControl, InputLabel, styled } from "@mui/material";
import { appData, PageFormData } from "../../data";

const MuiSelect = styled(Select)(() => ({
  "& .MuiOutlinedInput-notchedOutline": {
    padding: "10px 26px 10px 12px",
    borderColor: "rgb(0,0,0,0)!important",
  },
}));

const errorStyle = { border: "4px dashed red", borderRadius: "2vw" };

interface PurchaseChargerProp {
  selectedOption(name: string): void;
  data: PageFormData;
}

export default function ImageLayout1({
  selectedOption,
  data,
}: PurchaseChargerProp) {
  const [selection, setSelection] = React.useState(
    data.chargerPurchasePg.purchaseCharger
  );
  const [errorOutline, setErrorOutline] = React.useState(false);

  let menueOptions = appData.purchaseLeaseCharger.map((t: string) => (
    <MenuItem value={t} key={t}>
      {t}
    </MenuItem>
  ));

  const onNextClick = () => {
    setErrorOutline(selection === "");

    selection !== "" && selectedOption(selection);
  };
  return (
    <div className="container">
      <div className="purchaseCharger">
        <div className="title">
          <h1>WOULD YOU LIKE TO PURCHASE OR LEASE THE CHARGER FROM US?</h1>
        </div>

        <div className="mySelect" style={errorOutline ? errorStyle : {}}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel
              style={{
                fontSize: 20,
              }}
            >
              Please Select
            </InputLabel>
            <MuiSelect
              value={selection}
              label="Please Selected"
              onChange={(e: any) => setSelection(e.target.value)}
            >
              {menueOptions}
            </MuiSelect>
          </FormControl>
        </div>

        <div className="buttonContainer">
          <div className="button" onClick={onNextClick}>
            <h1>NEXT</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
