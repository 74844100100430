import './ImageLayout.css';

ImageLayout1YesNo.defaultProps = {
    title: 'SELECT YOUR VEHICLE MAKE',
    option_A: 'OPTION A',
}

interface ImageLayout1YNProp {
    selectedOption(name: string): void,
    title: string, 
    option_A_img: string,
}

export default function ImageLayout1YesNo({selectedOption, title, option_A_img}: ImageLayout1YNProp) {
    return (
        <div className="options_1_YesNo">
            <div className="header">
                <h1>{title}</h1>
            </div>
            <div className="optionImgContainer">
                <div className="optionImg" style={{background: `url(${option_A_img}) center`}}>
                </div>
            </div>

            <div className="option_Yes" onClick={() => selectedOption("Yes")}>
                <div className="item">
                    <h1>Yes</h1>
                </div>
            </div>
            <div className="option_No" onClick={() => selectedOption("No")}>
                <div className="item">
                    <h1>No</h1>
                </div>
            </div>
        </div>
    )
}

