import "./ImageLayout.css";

interface ImageLayout2Prop {
  selectedOption(name: string): void;
  title: string;
  option_A: string;
  option_A_img: string;
  option_B: string;
  option_B_img: string;
}

export default function ImageLayout2({
  selectedOption,
  title,
  option_A,
  option_A_img,
  option_B,
  option_B_img,
}: ImageLayout2Prop) {
  return (
    <div className="options_2">
      <div className="header">
        <h1>{title}</h1>
      </div>
      <div className="option_A" onClick={() => selectedOption(option_A)}>
        <div
          className="item"
          style={{
            background: `url(${option_A_img}) center`,
            backgroundSize: "cover",
          }}
        >
          <h1>{option_A}</h1>
        </div>
      </div>
      <div className="option_B" onClick={() => selectedOption(option_B)}>
        <div
          className="item"
          style={{ background: `url(${option_B_img}) center` }}
        >
          <h1>{option_B}</h1>
        </div>
      </div>
    </div>
  );
}

ImageLayout2.defaultProps = {
  title: "SELECT YOUR VEHICLE MAKE",
  option_A: "OPTION A",
  option_B: "OPTION B",
};
