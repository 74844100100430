import "./ImageLayout.css";

interface ImageLayout1Prop {
  selectedOption(name: string): void;
  title: string;
  option_A: string;
  option_A_img: string;
}

export default function ImageLayout1({
  selectedOption,
  title,
  option_A,
  option_A_img,
}: ImageLayout1Prop) {
  return (
    <div className="options_1">
      <div className="header">
        <h1>{title}</h1>
      </div>
      <div className="option_A">
        <div
          className="item"
          style={{
            background: `url(${option_A_img}) center`,
          }}
          onClick={() => selectedOption(option_A)}
        >
          <h1>{option_A}</h1>
        </div>
      </div>
    </div>
  );
}

ImageLayout1.defaultProps = {
  title: "SELECT YOUR VEHICLE MAKE",
  option_A: "OPTION A",
};
