import "./Pages.css";
import { ContactInfo, PageFormData } from ".././data";
import Contact from "../components/Contact/ContactInfo";

interface ContactProps {
  updateData(type: string, newData: ContactInfo): void;
  data: PageFormData;
}

export default function Jacuzzi({ updateData, data }: ContactProps) {
  const selectedOption = (option: ContactInfo) => {
    updateData("contactInfoPg", option);
  };

  return (
    <div className="C">
      <Contact selectedOption={selectedOption} data={data} />
    </div>
  );
}
