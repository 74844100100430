import "./Pages.css";
import { ElectricalInfo, PageFormData } from ".././data";
import Electrical from "../components/ElectricalInfo/ElectricalInfo";

interface ElectricalInfoProps {
  updateData(type: string, newData: ElectricalInfo): void;
  data: PageFormData;
}

export default function Jacuzzi({ updateData, data }: ElectricalInfoProps) {
  const selectedOption = (option: ElectricalInfo) => {
    updateData("electricalInfoPg", option);
  };

  return (
    <div className="C">
      <Electrical selectedOption={selectedOption} data={data} />
    </div>
  );
}
