import "./Pages.css";
import { PageFormData } from ".././data";
import Edit from "../components/Edit/Edit";

interface ContactProps {
  data: PageFormData;

  updateDataEdit: (data: PageFormData) => void;
}

export default function EditPG({ data, updateDataEdit }: ContactProps) {
  return (
    <div className="E">
      <Edit data={data} updateDataEdit={updateDataEdit} />
    </div>
  );
}
