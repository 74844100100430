import "../Pages.css";
import { appData, ChargerOption } from "../../data";
import ImageLayout3 from "../../components/ImageLayouts/ImageLayout3";

interface ChargingOptionTeslaProps {
  updateData(type: string, newData: ChargerOption): void;
}

export default function ChargingOptionTeslaPG({
  updateData,
}: ChargingOptionTeslaProps) {
  const selectedOption = (option: string) => {
    updateData("chargerOptionPg", {
      charger: option,
      cost:
        appData.chargingOptionTesla.cost[
          appData.chargingOptionTesla.type.indexOf(option)
        ],
    });
  };

  return (
    <div className="COT">
      <ImageLayout3
        selectedOption={selectedOption}
        title="SELECT CHARGING OPTION"
        option_A={appData.chargingOptionTesla.type[0]}
        option_B={appData.chargingOptionTesla.type[1]}
        option_C={appData.chargingOptionTesla.type[2]}
        option_A_img={appData.chargingOptionTesla.imgs[0]}
        option_B_img={appData.chargingOptionTesla.imgs[1]}
        option_C_img={appData.chargingOptionTesla.imgs[2]}
      />
    </div>
  );
}
