export interface PageFormData {
  addressPg: AddressData;
  vehicleMakePg: VehicleMake;
  houseTypePg: HouseType;
  chargerOptionPg: ChargerOption;
  electricalInfoPg: ElectricalInfo;
  jacuzziOptionPg: JacuzziOption;
  chargerPurchasePg: ChargerPurchase;
  chargerPurchaseTypePg: ChargerPurchaseType;
  contactInfoPg: ContactInfo;
}

export interface AddressData {
  address: string;
}
export interface VehicleMake {
  vehicle: string;
}

export interface HouseType {
  home: string;
}

export interface ChargerOption {
  charger: string;
  cost: number;
}

export interface ElectricalInfo {
  panalLocation: string;
  mainServerSize: string;
  photoElectricalPannle: File | undefined;
  photoMainBreaker: File | undefined;
  wireLenght: string;
}

export interface JacuzziOption {
  jacuzzi: string;
}

export interface ChargerPurchase {
  purchaseCharger: string;
}

export interface ChargerPurchaseType {
  purchaseChargerType: string;
  cost: number;
}

export interface ContactInfo {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  additionalInfo: string;
}

export const appData = {
  houseType: {
    type: ["DETACHED", "TOWNHOUSE", "COMMERCIAL", "SEMI-DETACHED"],
    imgs: [
      require("./images/Detached.jpg"),
      require("./images/Semi.jpg"),
      require("./images/Commercial.jpg"),
      require("./images/Townhouse.jpg"),
    ],
  },
  chargingOptionTesla: {
    type: ["TESLA HARD WIRED", "INDOOR 14-50 OUTLET", "OUTDOOR 14-50 OUTLET"],
    imgs: [
      require("./images/Tesla-Charger.jpg"),
      require("./images/1450-indoor.jpg"),
      require("./images/1450-outdoor-enclosure.jpg"),
    ],
    cost: [820, 820, 870],
  },
  chargingOptionOther: {
    type: ["LEVEL 2 HARDWIRED", "INDOOR 14-50 OUTLET", "OUTDOOR 14-50 OUTLET"],
    imgs: [
      require("./images/AW32-Outdoor.jpg"),
      require("./images/1450-indoor.jpg"),
      require("./images/1450-outdoor-enclosure.jpg"),
    ],
    cost: [820, 820, 870],
  },
  electricalPanelLocation: ["Main Floor", "Basement", "Garage"],
  mainServerSize: ["100A", "200A", "400A"],
  wireLenghtPanelToCharger: ["10ft", "20ft", "30ft", "40ft", "50ft"],
  wireCost: [0, 85.0, 125.0, 175.0, 275.0],
  purchaseLeaseCharger: [
    "Yes, I would like to purchase the charger",
    "Yes, I would like to lease-to-own the charger",
    "No, I will be providing my own charger",
  ],

  purchaseLeaseChargerOptionsTesla: {
    type: ["Tesla Wall Connector"],
    img: [require("./images/Tesla-Charger.jpg")],
    cost: [699.0],
  },
  purchaseLeaseChargerOptionsOther: {
    type: ["Phihong AW32", "JML Basic Level 2", "ChargePoint Home Flex"],
    img: [
      require("./images/AW32-Outdoor.jpg"),
      require("./images/JML-Basic-L2.jpg"),
      require("./images/ChargePoint.jpg"),
    ],
    cost: [699.0, 850.0, 1350.0],
  },
};
/*
("N/A Customer will provide EV charger");
//extra assumption
("No troubleshooting required for provided charger");
*/
