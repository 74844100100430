import React from "react";
import { appData, ElectricalInfo, PageFormData } from "../../data";
import "./ElectricalInfo.css";
import Multiselect from "./Multiselect";
import UploadFile from "./UploadFile";

interface ElectricalInfoProp {
  selectedOption(name: ElectricalInfo): void;
  data: PageFormData;
}

export default function Main({ selectedOption, data }: ElectricalInfoProp) {
  const [panelLocation, setPanelLocation] = React.useState(
    data.electricalInfoPg.panalLocation
  );
  const [mainServiceSize, setMainServiceSize] = React.useState(
    data.electricalInfoPg.mainServerSize
  );
  const [electricalPanelPhoto, setElectricalPanelPhoto] = React.useState<
    File | undefined
  >(data.electricalInfoPg.photoElectricalPannle);
  const [mainBreakerPhoto, setMainBreakerPhoto] = React.useState<
    File | undefined
  >(data.electricalInfoPg.photoMainBreaker);
  const [wireLenght, setWireLenght] = React.useState(
    data.electricalInfoPg.wireLenght
  );

  const mainBreakerSample = require("../../images/Breaker.JPG");
  const electricalPanelSample = require("../../images/Panel.JPG");
  const [sampleImgIndex, setSampleImgIndex] = React.useState(0); // what image to show for the sample image

  const electricalPanelStyle = {
    background: `url(${electricalPanelSample}) center`,
  };

  const mainBreakerStyle = {
    background: `url(${mainBreakerSample}) center`,
  };

  const [errorOutline, setErrorOutline] = React.useState({
    panalLocation: false,
    mainServerSize: false,
    photoElectricalPannle: false,
    photoMainBreaker: false,
    wireLenght: false,
  });

  const errorStyle = { border: "4px dashed red", borderRadius: "2vw" };

  const onNextClick = () => {
    //validation checks still need to be preformed
    //check if any fields are empty
    let errorFound =
      panelLocation === "" ||
      mainServiceSize === "" ||
      electricalPanelPhoto === undefined ||
      mainBreakerPhoto === undefined ||
      wireLenght === "";

    setErrorOutline({
      panalLocation: panelLocation === "",
      mainServerSize: mainServiceSize === "",
      photoElectricalPannle: electricalPanelPhoto === undefined,
      photoMainBreaker: mainBreakerPhoto === undefined,
      wireLenght: wireLenght === "",
    });

    wireLenght === "" &&
      setErrorOutline((prev) => {
        prev.wireLenght = true;
        return {
          ...prev,
        };
      });

    if (!errorFound) {
      const data: ElectricalInfo = {
        panalLocation: panelLocation,
        mainServerSize: mainServiceSize,
        photoElectricalPannle: electricalPanelPhoto!,
        photoMainBreaker: mainBreakerPhoto!,
        wireLenght: wireLenght,
      };

      selectedOption(data);
    } else {
      console.log("Errors were found");
    }

    //selectedOption(data);
  };

  return (
    <div className="electricalInfo">
      <div className="infoContainer">
        <div style={errorOutline.panalLocation ? errorStyle : {}}>
          <Multiselect
            title="ELECTRICAL PANEL LOCATION"
            options={appData.electricalPanelLocation}
            value={panelLocation}
            setValue={(value: string) => {
              setPanelLocation(value);
            }}
          />
        </div>
        <div style={errorOutline.mainServerSize ? errorStyle : {}}>
          <Multiselect
            title="MAIN SERVICE SIZE"
            options={appData.mainServerSize}
            value={mainServiceSize}
            setValue={(value: string) => {
              setMainServiceSize(value);
            }}
          />
        </div>

        <div
          style={errorOutline.photoElectricalPannle ? errorStyle : {}}
          onMouseOver={() => setSampleImgIndex(0)}
        >
          <UploadFile
            title="PHOTO OF THE ELECTRICAL PANEL"
            value={electricalPanelPhoto}
            setValue={(value: File) => {
              setElectricalPanelPhoto(value);
            }}
          />
        </div>

        <div
          style={errorOutline.photoMainBreaker ? errorStyle : {}}
          onMouseOver={() => setSampleImgIndex(1)}
        >
          <UploadFile
            title="PHOTO OF THE MAIN BREAKER"
            value={mainBreakerPhoto}
            setValue={(value: File) => {
              setMainBreakerPhoto(value);
            }}
          />
        </div>
        <div style={errorOutline.wireLenght ? errorStyle : {}}>
          <Multiselect
            title="WIRE LENGTH FROM PANEL TO CHARGER"
            options={appData.wireLenghtPanelToCharger}
            value={wireLenght}
            setValue={(value: string) => {
              setWireLenght(value);
            }}
          />
        </div>

        <div className="buttonContainer">
          <div className="button" onClick={onNextClick}>
            <h1>NEXT</h1>
          </div>
        </div>
      </div>
      <div className="sample">
        <div
          className="sampleImgBorder"
          style={sampleImgIndex === 0 ? electricalPanelStyle : mainBreakerStyle}
        ></div>
      </div>
    </div>
  );
}
