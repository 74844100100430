import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore, doc, setDoc } from "firebase/firestore";

import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { PageFormData } from "../data";
import { v4 as uuid } from "uuid";

const firebaseConfig = {
  apiKey: "AIzaSyBNzjdOUOZXQWbKohJH7PIymRSoHay_FmE",
  authDomain: "myevestimate-f3fb4.firebaseapp.com",
  projectId: "myevestimate-f3fb4",
  storageBucket: "myevestimate-f3fb4.appspot.com",
  messagingSenderId: "365303582208",
  appId: "1:365303582208:web:1ed9b936a8d47bf55b92e5",
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const firestore = getFirestore(app);

const firestoreDataUpload = (
  data: PageFormData,
  urlElectrical: string,
  urlBreaker: string,
  id: string
) => {
  console.log("Call");

  const electricalInfoPg = {
    panalLocation: data.electricalInfoPg.panalLocation,
    mainServerSize: data.electricalInfoPg.mainServerSize,
    photoElectricalPannle: urlElectrical,
    photoMainBreaker: urlBreaker,
    wireLenght: data.electricalInfoPg.wireLenght,
  };

  const returnData = {
    addressPg: data.addressPg,
    chargerOptionPg: data.chargerOptionPg,
    chargerPurchasePg: data.chargerPurchasePg,
    chargerPurchaseTypePg: data.chargerPurchaseTypePg,
    contactInfoPg: data.contactInfoPg,
    electricalInfoPg: electricalInfoPg,
    houseTypePg: data.houseTypePg,
    jacuzziOptionPg: data.jacuzziOptionPg,
    vehicleMakePg: data.vehicleMakePg,
  };

  const fireStoreUploadTask = setDoc(doc(firestore, `JML/${id}`), returnData);
  fireStoreUploadTask.then(() => console.log("Upladed"));
  fireStoreUploadTask.catch((err) => console.log("ERRORRRR"));
};

export const uploadData = (data: PageFormData) => {
  const file = data.electricalInfoPg.photoElectricalPannle;
  const file2 = data.electricalInfoPg.photoMainBreaker;
  if (!file || !file2) return;
  const id = uuid();
  const storageRef = ref(storage, `/jml/${id}/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  const storageRef2 = ref(storage, `/jml/${id}/${file2.name}`);
  const uploadTask2 = uploadBytesResumable(storageRef2, file2);

  uploadTask.on(
    "state_changed",
    (snapshot) => {},
    (err) => {},
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((urlElectrical) => {
        uploadTask2.on(
          "state_changed",
          (snapshot) => {},
          (err) => {},
          () => {
            getDownloadURL(uploadTask2.snapshot.ref).then((urlBreaker) => {
              console.log(urlElectrical);
              firestoreDataUpload(data, urlElectrical, urlBreaker, id);
            });
          }
        );
      });
    }
  );

  // //upload first image
  // uploadTask.on(
  //   "state_changed",
  //   (err) => {
  //     console.log("Error1: ", err);
  //   },
  //   () => {
  //     getDownloadURL(uploadTask.snapshot.ref).then((urlElectrical) => {
  //       //upload second image
  //       uploadTask2.on(
  //         "state_changed",
  //         (err) => {
  //           console.log("Error2");
  //         },
  //         () => {
  //           getDownloadURL(uploadTask2.snapshot.ref).then((urlBreaker) => {
  //             //save data
  //             console.log("Callls");

  //             firestoreDataUpload(data, urlElectrical, urlBreaker, id);
  //           });
  //         }
  //       );
  //     });
  //   }
  // );
};
