import './BusinessCredential.css';

export default function BusinessCredentials(){
    const points = [
        {
            id: 1,
            text: "Operating Since 1997"
        },
        {
            id: 2,
            text: "Over 5000 Charging Stations Installed"
        },
        {
            id: 3,
            text: "10/10 On HomeStars"
        },
        {
            id: 4,
            text: "Master Electrician Certification"
        },
        {
            id: 5,
            text: "309A Electrician Construction and Maintenance"
        },
        {
            id: 6,
            text: "Eaton ECCN Certified Contractor"
        },
        {
            id: 7,
            text: "Sun Country Highway Certified Dealer, Installer"
        },
        {
            id: 8,
            text: "Lutron Lighting Control Certified Programmer"
        },
        {
            id: 9,
            text: "AZ Drivers License"
        },
        {
            id: 10,
            text: "Heavy Equipment Operator"
        },
    ]


    return(
        <div className="BusinessCredentialContainer">
            <ul>
            {points.map((point) => (
                <li key={point.id}>{point.text}</li>
            ))}
            </ul>
        </div>
    )
}