import React from "react";
import "./ElectricalInfo.css";
import { CgSoftwareUpload } from "react-icons/cg";

interface UploadFileProp {
  title: string;
  value: File | undefined;
  setValue: (value: File) => void;
}

export default function Main({ title, value, setValue }: UploadFileProp) {
  const inputRef = React.createRef<HTMLInputElement>();

  const selectedFile = (file: any) => {
    setValue(file.target.files[0]);
  };

  return (
    <div className="MultiselectTitle">
      <div className="title">
        <h1>{title}</h1>
      </div>

      <div className="mySelect">
        <input
          type="file"
          style={{ display: "none" }}
          onChange={(e: any) => selectedFile(e)}
          ref={inputRef}
        />

        <div className="mySelect" onClick={() => inputRef.current?.click()}>
          <div className="mySelectContent">
            <p>{value === undefined ? "Upload" : value.name}</p>
            <CgSoftwareUpload />
          </div>
        </div>
      </div>
    </div>
  );
}
